<template>
  <div class="bgContent">
    <div class="hidden_cont">
      <div class="homeItemBg flexAC">
        <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">隐患管理</span>
      </div>

      <!-- 内容 -->
      <div class="cont_box">
        <div class="cont_top">
          <div class="er_item1" @click="tabClick(1)" :class="{'selected': tabId == 1}">告警信息</div>
          <div class="er_item2" @click="tabClick(2)" :class="{'selected': tabId == 2}">处理记录</div>
          <div class="er_item3" @click="tabClick(3)" :class="{'selected': tabId == 3}">分派记录</div>
          <div class="er_item4" @click="tabClick(4)" :class="{'selected': tabId == 4}">督办记录</div>
        </div>
        <div class="alarm_list">
          <WarnList v-if="tabId == 1" ></WarnList>
          <DealList v-if="tabId == 2"></DealList>
          <DispList v-if="tabId == 3"></DispList>
          <OverseList v-if="tabId == 4"></OverseList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarnList from '@/components/work/alarmDetail/warnList.vue'
import DealList from '@/components/work/alarmDetail/dealList.vue'
import DispList from '@/components/work/alarmDetail/dispList.vue'
import OverseList from '@/components/work/alarmDetail/overseList.vue'
export default {
  components: {
    WarnList,
    DealList,
    DispList,
    OverseList
  },
  data () {
    return {
      tabId: 1
    }
  },
  watch: {

  },
  mounted () {

  },
  methods: {
    tabClick (index) {
      this.tabId = index
    }
  }
}
</script>

<style scoped>
.hidden_cont{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 40px;
  box-sizing: border-box;
}
.cont_box,.alarm_list{
  width: 100%;
  height: auto;
  position: relative;
}
.cont_top{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0;
}
.er_item1 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 20px 0px 0px 20px;
}
.er_item2, .er_item3 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
}
.er_item2 {
  margin: 0 2px;
}
.er_item3 {
  margin-right: 2px;
}
.er_item4 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 0px 20px 20px 0px;
}
.selected {
  color: #FFAD42;
}
</style>
